import * as React from "react";

export const LogoMobileIcon = () => (
  <svg width="188" height="32" viewBox="0 0 188 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5818 20.5829C54.4255 20.968 53.1754 21.1606 51.8316 21.1606C47.1439 21.1606 44.8 18.7696 44.8 13.9877C44.8 9.46253 47.1439 7.19995 51.8316 7.19995C53.1754 7.19995 54.4255 7.39251 55.5818 7.77763V9.31811C54.4255 8.86881 53.2379 8.64415 52.0191 8.64415C48.3627 8.64415 46.5345 10.4253 46.5345 13.9877C46.5345 17.8068 48.3627 19.7164 52.0191 19.7164C53.2379 19.7164 54.4255 19.4917 55.5818 19.0424V20.5829Z" fill="white"/>
      <path d="M58.2631 21.0643V10.9549H59.4351L59.7445 12.245C60.607 11.3849 61.6445 10.9549 62.8571 10.9549V12.4376C61.682 12.4376 60.7289 12.8644 59.9976 13.7181V21.0643H58.2631Z" fill="white"/>
      <path d="M63.429 10.9549H65.3135L68.098 18.8498L70.9762 10.9549H72.8138L68.7261 21.3628C68.0011 23.1985 66.9135 24.3442 65.4635 24.7999L64.7978 23.5387C65.8979 23.0444 66.6979 22.2389 67.1979 21.122L63.429 10.9549Z" fill="white"/>
      <path d="M74.3514 20.5829V19.0424C75.5077 19.4275 76.6765 19.6201 77.8578 19.6201C79.4266 19.6201 80.211 19.1387 80.211 18.1759C80.211 17.2773 79.6641 16.828 78.5703 16.828H77.164C75.0389 16.828 73.9764 15.8331 73.9764 13.8433C73.9764 11.9177 75.342 10.9549 78.0734 10.9549C79.2485 10.9549 80.4142 11.1153 81.5705 11.4363V12.9768C80.4142 12.5916 79.2485 12.3991 78.0734 12.3991C76.4358 12.3991 75.6171 12.8805 75.6171 13.8433C75.6171 14.7419 76.1327 15.1912 77.164 15.1912H78.5703C80.8204 15.1912 81.9455 16.1861 81.9455 18.1759C81.9455 20.1015 80.5829 21.0643 77.8578 21.0643C76.6765 21.0643 75.5077 20.9038 74.3514 20.5829Z" fill="white"/>
      <path d="M84.2893 9.31811H85.3769L85.7519 10.9549H88.4145V12.3991H86.0238V18.2048C86.0238 19.1483 86.4207 19.6201 87.2145 19.6201H88.4145V21.0643H86.7644C85.1144 21.0643 84.2893 20.156 84.2893 18.3396V9.31811Z" fill="white"/>
      <path d="M90.1584 18.0122C90.1584 15.939 91.4897 14.9024 94.1523 14.9024C95.0086 14.9024 95.8649 14.9665 96.7212 15.0949V14.1417C96.7212 12.98 95.8367 12.3991 94.0679 12.3991C93.0554 12.3991 92.0178 12.5595 90.9553 12.8805V11.4363C92.0178 11.1153 93.0554 10.9549 94.0679 10.9549C96.993 10.9549 98.4556 12.0011 98.4556 14.0936V21.0643H97.4806L96.8805 20.063C95.8992 20.7305 94.8304 21.0643 93.6741 21.0643C91.3303 21.0643 90.1584 20.0469 90.1584 18.0122ZM94.1523 16.3466C92.646 16.3466 91.8928 16.8921 91.8928 17.9833C91.8928 19.0745 92.4866 19.6201 93.6741 19.6201C94.8742 19.6201 95.8899 19.2927 96.7212 18.638V16.5391C95.8649 16.4107 95.0086 16.3466 94.1523 16.3466Z" fill="white"/>
      <path d="M103.106 7.29623V21.0643H101.371V7.29623H103.106Z" fill="white"/>
      <path d="M107.85 7.29623V21.0643H106.115V7.29623H107.85Z" fill="white"/>
      <path d="M112.031 15.9711C112.031 18.4037 113.016 19.6201 114.984 19.6201C116.953 19.6201 117.938 18.4037 117.938 15.9711C117.938 13.5897 116.953 12.3991 114.984 12.3991C113.016 12.3991 112.031 13.5897 112.031 15.9711ZM110.297 16.0096C110.297 12.6398 111.859 10.9549 114.984 10.9549C118.11 10.9549 119.672 12.6398 119.672 16.0096C119.672 19.373 118.11 21.0547 114.984 21.0547C111.872 21.0547 110.309 19.373 110.297 16.0096Z" fill="white"/>
      <path d="M128.598 12.6687C128.004 12.5146 127.269 12.4376 126.394 12.4376C124.301 12.4376 123.254 13.6026 123.254 15.9326C123.254 18.3267 124.244 19.5238 126.226 19.5238C127.126 19.5238 127.916 19.3505 128.598 19.0039V12.6687ZM130.332 21.0643C130.332 23.5034 128.823 24.7229 125.804 24.7229C124.591 24.7229 123.516 24.5625 122.579 24.2415V22.7973C123.535 23.1183 124.616 23.2787 125.822 23.2787C127.673 23.2787 128.598 22.5406 128.598 21.0643V20.5829C127.86 20.9038 127.066 21.0643 126.216 21.0643C123.104 21.0643 121.547 19.3601 121.547 15.9518C121.547 12.6205 123.188 10.9549 126.469 10.9549C127.801 10.9549 129.088 11.0929 130.332 11.3689V21.0643Z" fill="white"/>
      <path d="M133.154 21.0643V10.9549H134.326L134.635 12.245C135.498 11.3849 136.535 10.9549 137.748 10.9549V12.4376C136.573 12.4376 135.62 12.8644 134.889 13.7181V21.0643H133.154Z" fill="white"/>
      <path d="M139.023 18.0122C139.023 15.939 140.354 14.9024 143.017 14.9024C143.873 14.9024 144.73 14.9665 145.586 15.0949V14.1417C145.586 12.98 144.701 12.3991 142.933 12.3991C141.92 12.3991 140.883 12.5595 139.82 12.8805V11.4363C140.883 11.1153 141.92 10.9549 142.933 10.9549C145.858 10.9549 147.32 12.0011 147.32 14.0936V21.0643H146.345L145.745 20.063C144.764 20.7305 143.695 21.0643 142.539 21.0643C140.195 21.0643 139.023 20.0469 139.023 18.0122ZM143.017 16.3466C141.511 16.3466 140.758 16.8921 140.758 17.9833C140.758 19.0745 141.351 19.6201 142.539 19.6201C143.739 19.6201 144.755 19.2927 145.586 18.638V16.5391C144.73 16.4107 143.873 16.3466 143.017 16.3466Z" fill="white"/>
      <path d="M151.877 19.0039C152.558 19.3505 153.318 19.5238 154.155 19.5238C156.199 19.5238 157.221 18.2625 157.221 15.74C157.221 13.5384 156.205 12.4376 154.174 12.4376C153.299 12.4376 152.533 12.5146 151.877 12.6687V19.0039ZM150.142 11.3689C151.449 11.0929 152.799 10.9549 154.193 10.9549C157.349 10.9549 158.927 12.5563 158.927 15.7592C158.927 19.2959 157.34 21.0643 154.164 21.0643C153.377 21.0643 152.614 20.9038 151.877 20.5829V24.7229H150.142V11.3689Z" fill="white"/>
      <path d="M161.28 21.0643V7.29623H163.015V12.0525C163.852 11.3207 164.874 10.9549 166.081 10.9549C168.537 10.9549 169.765 12.2097 169.765 14.7194V21.0643H168.031V14.6905C168.031 13.1886 167.303 12.4376 165.846 12.4376C164.852 12.4376 163.909 12.8644 163.015 13.7181V21.0643H161.28Z" fill="white"/>
      <path d="M171.415 10.9549H173.3L176.084 18.8498L178.962 10.9549H180.8L176.712 21.3628C175.987 23.1985 174.9 24.3442 173.45 24.7999L172.784 23.5387C173.884 23.0444 174.684 22.2389 175.184 21.122L171.415 10.9549Z" fill="white"/>
      <path d="M10.2571 11.4642C10.2571 12.1856 9.67072 12.7721 8.94937 12.7721C8.22802 12.7721 7.64163 12.1856 7.64163 11.4642C7.64163 10.7427 8.22802 10.1562 8.94937 10.1562C9.67072 10.1562 10.2571 10.7427 10.2571 11.4642Z" fill="#FB9B2B"/>
      <path d="M22.8039 22.556C23.9657 22.556 24.9075 21.614 24.9075 20.4521C24.9075 19.2902 23.9657 18.3483 22.8039 18.3483C21.6422 18.3483 20.7004 19.2902 20.7004 20.4521C20.7004 21.614 21.6422 22.556 22.8039 22.556Z" fill="#FB9B2B"/>
      <path d="M21.5404 1.35218C21.7638 1.08454 22.0803 0.921636 22.427 0.889054C22.4665 0.886727 22.5038 0.884399 22.5433 0.884399C22.8505 0.884399 23.1437 0.991454 23.381 1.1916C23.6486 1.41502 23.8115 1.73153 23.8441 2.07829C23.8743 2.42738 23.7673 2.76484 23.5439 3.03247C23.0808 3.58404 22.2548 3.65618 21.701 3.19305C21.4334 2.96964 21.2705 2.65313 21.2379 2.30636C21.21 1.9596 21.3147 1.61982 21.5404 1.35218Z" fill="#FB9B2B"/>
      <path d="M10.8691 21.0642C11.3415 21.0642 11.7254 21.4482 11.7254 21.9206C11.7254 22.3931 11.3415 22.7771 10.8691 22.7771C10.3967 22.7771 10.0128 22.3931 10.0128 21.9206C10.0128 21.4482 10.3967 21.0642 10.8691 21.0642Z" fill="white"/>
      <path d="M12.3374 0C13.0587 0 13.6451 0.586473 13.6451 1.30793C13.6451 2.02938 13.0587 2.61585 12.3374 2.61585C11.616 2.61585 11.0297 2.02938 11.0297 1.30793C11.0297 0.586473 11.616 0 12.3374 0Z" fill="white"/>
      <path d="M16.1582 31.1389C16.0395 31.4671 15.7975 31.7301 15.4811 31.8767C15.1646 32.0233 14.8086 32.0396 14.4805 31.9209C13.8034 31.6742 13.452 30.9225 13.6987 30.2453C13.9453 29.568 14.6969 29.2166 15.374 29.4633C15.7021 29.582 15.9651 29.8217 16.1117 30.1405C16.2629 30.4547 16.2792 30.8108 16.1582 31.1389Z" fill="white"/>
      <path d="M30.5433 15.7719C30.5433 16.2188 30.5224 16.6679 30.4805 17.1124C30.3316 17.1241 30.1826 17.1497 30.036 17.1892C29.8662 17.2335 29.7056 17.2963 29.552 17.3731L22.7434 9.46736C22.9459 9.14619 23.0645 8.76685 23.0645 8.35957C23.0645 7.60786 22.6643 6.94692 22.064 6.58154L22.534 4.72205C22.5363 4.72205 22.5387 4.72205 22.541 4.72205C23.1995 4.72205 23.8534 4.46605 24.342 3.97034C28.235 6.65136 30.5433 11.022 30.5433 15.7719Z" fill="white"/>
      <path d="M28.4979 18.3692C28.3327 18.6555 28.228 18.9627 28.1838 19.2792L25.9942 19.5049C25.5962 18.1667 24.3769 17.1776 22.9226 17.1264L21.9057 10.368L28.6259 18.1714C28.5794 18.2366 28.5375 18.3017 28.4979 18.3692Z" fill="white"/>
      <path d="M15.9418 28.3718C15.8929 28.3509 15.8464 28.3299 15.7952 28.3137C15.3484 28.1507 14.8877 28.1228 14.4526 28.2066L12.2583 23.4683C12.5957 23.1634 12.833 22.7515 12.9168 22.286L19.6323 21.4598C19.7766 21.9137 20.0163 22.3233 20.3257 22.67L15.9418 28.3718Z" fill="white"/>
      <path d="M9.37055 20.4777L3.77659 17.6501C3.7859 17.6105 3.79288 17.5686 3.80219 17.5267C3.88596 17.0473 3.83244 16.5632 3.65094 16.1234L7.33448 13.4121C7.73471 13.7449 8.23966 13.9567 8.79114 13.9893L9.90807 20.0728C9.70795 20.1798 9.52645 20.3171 9.37055 20.4777Z" fill="white"/>
      <path d="M12.3374 3.8401C12.9051 3.8401 13.431 3.65159 13.8545 3.33508L19.0576 7.58003C18.9715 7.79414 18.9179 8.02454 18.9063 8.26657L11.1762 10.261C10.9715 9.8817 10.6713 9.56286 10.3083 9.33246L12.1419 3.83545C12.2047 3.83777 12.2699 3.8401 12.3374 3.8401Z" fill="white"/>
      <path d="M19.4834 20.2426L12.7679 21.0688C12.7074 20.9361 12.6352 20.8081 12.5491 20.6918L20.2862 10.3191C20.4118 10.3633 20.5421 10.3959 20.6748 10.4168L21.7126 17.3056C20.4747 17.7408 19.5672 18.8811 19.4834 20.2426Z" fill="white"/>
      <path d="M9.99417 13.7705C10.8691 13.3725 11.4811 12.4881 11.4811 11.4641C11.4811 11.4572 11.4811 11.4502 11.4811 11.4432L19.2112 9.44873C19.2414 9.49528 19.2717 9.54182 19.3042 9.58604L11.5672 19.961C11.4229 19.9098 11.2693 19.8726 11.1111 19.854L9.99417 13.7705Z" fill="white"/>
      <path d="M20.0209 1.95718C20.007 2.10613 20.007 2.25973 20.0186 2.41333C20.0768 3.08824 20.3956 3.69798 20.9145 4.13318C21.0518 4.24722 21.196 4.34496 21.3473 4.42642L20.8772 6.28358C20.491 6.3022 20.1326 6.42787 19.8301 6.63035L14.6271 2.3854C14.7551 2.11776 14.8342 1.8222 14.8598 1.51267C15.3112 1.47078 15.7696 1.44751 16.2234 1.44751C17.5148 1.44984 18.79 1.61973 20.0209 1.95718Z" fill="white"/>
      <path d="M10.2571 2.74854C10.4502 3.02548 10.6946 3.26286 10.9808 3.44439L9.14483 8.94141C9.07967 8.93675 9.01452 8.9321 8.94704 8.9321C7.55088 8.9321 6.41534 10.0678 6.41534 11.4642C6.41534 11.8039 6.48282 12.1298 6.60614 12.4277L2.92494 15.1389C2.64338 14.9039 2.31063 14.734 1.94995 14.6386C2.35484 9.48133 5.54972 4.90824 10.2571 2.74854Z" fill="white"/>
      <path d="M2.36417 19.3886C2.50146 19.3257 2.63409 19.2512 2.75974 19.1628C2.93427 19.0418 3.09017 18.8998 3.22513 18.7439L8.81675 21.5715C8.79814 21.6856 8.7865 21.8019 8.7865 21.923C8.7865 23.0703 9.7196 24.0035 10.8668 24.0035C10.9622 24.0035 11.0553 23.9966 11.146 23.9849L13.3403 28.7232C13.0425 28.963 12.7935 29.2748 12.6259 29.6402C7.61606 28.3416 3.66725 24.3992 2.36417 19.3886Z" fill="white"/>
      <path d="M17.3775 30.0475C17.3403 29.9032 17.2891 29.7612 17.2239 29.6216C17.1379 29.44 17.0331 29.2701 16.9121 29.1165L21.2938 23.4171C21.7475 23.6475 22.2594 23.7801 22.8016 23.7801C24.5445 23.7801 25.9779 22.4326 26.1175 20.7244L28.3095 20.4987C28.4886 20.9967 28.8167 21.4203 29.2519 21.7205C27.1018 26.4285 22.534 29.6309 17.3775 30.0475Z" fill="white"/>
      <path d="M20.9843 7.50537C21.4566 7.50537 21.8406 7.88937 21.8406 8.36181C21.8406 8.83424 21.4566 9.21824 20.9843 9.21824C20.5119 9.21824 20.128 8.83424 20.128 8.36181C20.128 7.88937 20.5119 7.50537 20.9843 7.50537Z" fill="#658DC6"/>
      <path d="M0.0209249 16.861C0.0814252 16.5166 0.272234 16.2163 0.558447 16.0162C0.781832 15.8603 1.04012 15.7788 1.30539 15.7788C1.38218 15.7788 1.45897 15.7858 1.53576 15.7998C1.88015 15.8603 2.18032 16.0511 2.38044 16.3374C2.58055 16.6236 2.65734 16.9704 2.59684 17.3148C2.53634 17.6592 2.34553 17.9595 2.05932 18.1596C1.77311 18.3598 1.42639 18.4366 1.08201 18.376C0.369965 18.2504 -0.10473 17.5708 0.0209249 16.861Z" fill="#658DC6"/>
      <path d="M31.8231 20.289C31.6486 20.5916 31.3671 20.808 31.0297 20.8988C30.6923 20.9895 30.3409 20.943 30.0384 20.7685C29.7359 20.5939 29.5195 20.3123 29.4287 19.9749C29.338 19.6374 29.3845 19.286 29.559 18.9834C29.7336 18.6809 30.0151 18.4644 30.3525 18.3737C30.4642 18.3434 30.5806 18.3295 30.6923 18.3295C30.918 18.3295 31.1414 18.39 31.3438 18.5063C31.6463 18.6809 31.8627 18.9625 31.9535 19.2999C32.0442 19.6351 31.9977 19.9865 31.8231 20.289Z" fill="#658DC6"/>
  </svg>
);
