export * from "./about";
export * from "./authors";
export * from "./author-details";
export * from "./catalog";
export * from "./contact";
export * from "./login";
export * from "./register";
export * from "./news";
export * from "./not-found";
export * from "./search-main";
export * from "./profile";
export * from "./search-history";
export * from "./details";
export * from "./search";
export * from "./results";
export * from "./offline";
