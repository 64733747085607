import * as React from "react";

export const OfflinePage = ()=> {
    // @TODO - check - navigator offline
    return (
        <div>
            <header className="app-layout-header">
                <h2 className="text-primary"></h2>
            </header>
            <div className="app-layout-content">
            </div>
        </div>
    );
}
