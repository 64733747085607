import * as React from "react";

export const LogoIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8214 14.33C12.8214 15.2317 12.0884 15.9647 11.1867 15.9647C10.2851 15.9647 9.55208 15.2317 9.55208 14.33C9.55208 13.4283 10.2851 12.6953 11.1867 12.6953C12.0884 12.6953 12.8214 13.4283 12.8214 14.33Z" fill="#FB9B2B"/>
      <path d="M28.505 28.1942C29.9571 28.1942 31.1344 27.0169 31.1344 25.5647C31.1344 24.1125 29.9571 22.9353 28.505 22.9353C27.0528 22.9353 25.8755 24.1125 25.8755 25.5647C25.8755 27.0169 27.0528 28.1942 28.505 28.1942Z" fill="#FB9B2B"/>
      <path d="M26.9255 1.68987C27.2048 1.35537 27.6004 1.15176 28.0337 1.11104C28.0832 1.10813 28.1297 1.10522 28.1792 1.10522C28.5631 1.10522 28.9296 1.23902 29.2263 1.48917C29.5608 1.7684 29.7644 2.16398 29.8051 2.59737C29.8429 3.03367 29.7091 3.45543 29.4299 3.78992C28.8511 4.47928 27.8185 4.56945 27.1262 3.99062C26.7917 3.71139 26.5881 3.31581 26.5474 2.88242C26.5125 2.44903 26.6434 2.02436 26.9255 1.68987Z" fill="#FB9B2B"/>
      <path d="M13.5864 26.3301C14.1769 26.3301 14.6568 26.81 14.6568 27.4005C14.6568 27.9909 14.1769 28.4709 13.5864 28.4709C12.9959 28.4709 12.516 27.9909 12.516 27.4005C12.516 26.81 12.9959 26.3301 13.5864 26.3301Z" fill="white"/>
      <path d="M15.4218 0C16.3235 0 17.0564 0.732984 17.0564 1.63467C17.0564 2.53636 16.3235 3.26934 15.4218 3.26934C14.5201 3.26934 13.7871 2.53636 13.7871 1.63467C13.7871 0.732984 14.5201 0 15.4218 0Z" fill="white"/>
      <path d="M20.1978 38.9233C20.0495 39.3334 19.747 39.6621 19.3514 39.8454C18.9558 40.0286 18.5108 40.049 18.1007 39.9006C17.2542 39.5923 16.815 38.6528 17.1233 37.8064C17.4317 36.96 18.3712 36.5207 19.2176 36.8291C19.6277 36.9774 19.9564 37.277 20.1396 37.6755C20.3287 38.0682 20.3491 38.5132 20.1978 38.9233Z" fill="white"/>
      <path d="M38.1792 19.7142C38.1792 20.2727 38.153 20.8342 38.1006 21.3898C37.9145 21.4043 37.7283 21.4363 37.5451 21.4858C37.3328 21.5411 37.1321 21.6196 36.9401 21.7156L28.4293 11.8334C28.6824 11.432 28.8307 10.9578 28.8307 10.4487C28.8307 9.50907 28.3304 8.68289 27.58 8.22616L28.1675 5.9018C28.1704 5.9018 28.1734 5.9018 28.1763 5.9018C28.9994 5.9018 29.8168 5.58179 30.4276 4.96216C35.2938 8.31343 38.1792 13.7767 38.1792 19.7142Z" fill="white"/>
      <path d="M35.6225 22.9614C35.4159 23.3192 35.2851 23.7032 35.2298 24.0989L32.4927 24.3811C31.9954 22.7083 30.4712 21.472 28.6533 21.408L27.3822 12.96L35.7824 22.7141C35.7243 22.7956 35.6719 22.8771 35.6225 22.9614Z" fill="white"/>
      <path d="M19.9273 35.4647C19.8662 35.4385 19.808 35.4123 19.744 35.392C19.1856 35.1883 18.6097 35.1534 18.0657 35.2582L15.3229 29.3353C15.7446 28.9542 16.0413 28.4393 16.146 27.8574L24.5404 26.8247C24.7208 27.392 25.0204 27.904 25.4072 28.3374L19.9273 35.4647Z" fill="white"/>
      <path d="M11.7132 25.5971L4.72077 22.0626C4.73241 22.0131 4.74113 21.9608 4.75277 21.9084C4.85748 21.3091 4.79058 20.704 4.56371 20.1542L9.16813 16.7651C9.66842 17.1811 10.2996 17.4459 10.989 17.4866L12.3851 25.091C12.135 25.2248 11.9081 25.3964 11.7132 25.5971Z" fill="white"/>
      <path d="M15.4218 4.79949C16.1315 4.79949 16.7888 4.56385 17.3182 4.16821L23.822 9.47439C23.7144 9.74203 23.6475 10.03 23.6329 10.3326L13.9703 12.8257C13.7144 12.3515 13.3392 11.9529 12.8854 11.6649L15.1774 4.79367C15.256 4.79658 15.3374 4.79949 15.4218 4.79949Z" fill="white"/>
      <path d="M24.3543 25.3033L15.9599 26.336C15.8842 26.1702 15.7941 26.0102 15.6864 25.8647L25.3578 12.8989C25.5148 12.9542 25.6777 12.9949 25.8435 13.0211L27.1408 21.632C25.5934 22.176 24.459 23.6015 24.3543 25.3033Z" fill="white"/>
      <path d="M12.4927 17.213C13.5864 16.7155 14.3514 15.6101 14.3514 14.3301C14.3514 14.3213 14.3514 14.3126 14.3514 14.3039L24.0139 11.8108C24.0518 11.869 24.0896 11.9272 24.1303 11.9824L14.459 24.9512C14.2786 24.8872 14.0867 24.8406 13.8889 24.8173L12.4927 17.213Z" fill="white"/>
      <path d="M25.0262 2.44608C25.0087 2.63224 25.0087 2.82421 25.0233 3.01618C25.096 3.85969 25.4945 4.62176 26.1431 5.16569C26.3147 5.30821 26.4951 5.43037 26.6841 5.53218L26.0966 7.85329C25.6137 7.87656 25.1658 8.03363 24.7877 8.28669L18.2839 2.98128C18.4439 2.64678 18.5428 2.27738 18.5748 1.89052C19.139 1.83817 19.712 1.80908 20.2792 1.80908C21.8935 1.81199 23.4875 2.02432 25.0262 2.44608Z" fill="white"/>
      <path d="M12.8214 3.43506C13.0628 3.78124 13.3682 4.07797 13.726 4.30488L11.4311 11.1761C11.3496 11.1703 11.2682 11.1645 11.1838 11.1645C9.43863 11.1645 8.0192 12.5841 8.0192 14.3296C8.0192 14.7543 8.10355 15.1616 8.25771 15.534L3.6562 18.9231C3.30425 18.6292 2.88831 18.4169 2.43747 18.2976C2.94358 11.8511 6.93718 6.13469 12.8214 3.43506Z" fill="white"/>
      <path d="M2.9552 24.2357C3.12681 24.1572 3.29261 24.0641 3.44967 23.9536C3.66782 23.8023 3.8627 23.6248 4.03141 23.4299L11.0209 26.9645C10.9977 27.107 10.9831 27.2525 10.9831 27.4037C10.9831 28.8379 12.1495 30.0045 13.5835 30.0045C13.7027 30.0045 13.8191 29.9957 13.9325 29.9812L16.6754 35.9041C16.3031 36.2037 15.9918 36.5936 15.7824 37.0503C9.52006 35.427 4.58405 30.499 2.9552 24.2357Z" fill="white"/>
      <path d="M21.7219 37.5593C21.6754 37.3789 21.6114 37.2015 21.53 37.0269C21.4223 36.8 21.2914 36.5877 21.1402 36.3957L26.6172 29.2713C27.1844 29.5593 27.8243 29.7251 28.502 29.7251C30.6806 29.7251 32.4724 28.0407 32.6469 25.9055L35.3869 25.6233C35.6108 26.2458 36.0209 26.7753 36.5649 27.1506C33.8773 33.0357 28.1675 37.0386 21.7219 37.5593Z" fill="white"/>
      <path d="M26.2304 9.38184C26.8208 9.38184 27.3008 9.86177 27.3008 10.4522C27.3008 11.0427 26.8208 11.5226 26.2304 11.5226C25.6399 11.5226 25.16 11.0427 25.16 10.4522C25.16 9.86177 25.6399 9.38184 26.2304 9.38184Z" fill="#658DC6"/>
      <path d="M0.026179 21.0762C0.101804 20.6457 0.340315 20.2705 0.698081 20.0203C0.977313 19.8254 1.30018 19.7236 1.63176 19.7236C1.72775 19.7236 1.82374 19.7324 1.91972 19.7498C2.3502 19.8254 2.72542 20.0639 2.97557 20.4217C3.22571 20.7795 3.3217 21.2129 3.24607 21.6434C3.17045 22.0738 2.93194 22.4491 2.57417 22.6992C2.21641 22.9493 1.78301 23.0453 1.35253 22.9697C0.462479 22.8126 -0.130889 21.9633 0.026179 21.0762Z" fill="#658DC6"/>
      <path d="M39.7789 25.3612C39.5608 25.7393 39.2088 26.0098 38.7871 26.1233C38.3653 26.2367 37.9261 26.1785 37.548 25.9604C37.1699 25.7422 36.8994 25.3903 36.7859 24.9685C36.6725 24.5468 36.7306 24.1076 36.9488 23.7294C37.1669 23.3513 37.5189 23.0808 37.9407 22.9674C38.0803 22.9296 38.2257 22.9121 38.3653 22.9121C38.6475 22.9121 38.9267 22.9877 39.1798 23.1332C39.5579 23.3513 39.8284 23.7033 39.9418 24.125C40.0553 24.5439 39.9971 24.9831 39.7789 25.3612Z" fill="#658DC6"/>
  </svg>
);
