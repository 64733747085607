import * as React from "react";

export const TitleIcon = () => (
  <svg width="176" height="20" viewBox="0 0 176 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.2355 15.2079C10.9233 15.6455 9.50469 15.8643 7.97969 15.8643C2.6599 15.8643 0 13.1473 0 7.71335C0 2.57112 2.6599 0 7.97969 0C9.50469 0 10.9233 0.218818 12.2355 0.656455V2.407C10.9233 1.89643 9.57563 1.64114 8.19248 1.64114C4.04304 1.64114 1.96832 3.66521 1.96832 7.71335C1.96832 12.0532 4.04304 14.2232 8.19248 14.2232C9.57563 14.2232 10.9233 13.9679 12.2355 13.4573V15.2079Z" fill="white"/>
<path d="M15.2784 15.7549V4.26696H16.6084L16.9595 5.73304C17.9383 4.75565 19.1158 4.26696 20.4918 4.26696V5.95186C19.1583 5.95186 18.0767 6.43691 17.2468 7.407V15.7549H15.2784Z" fill="white"/>
<path d="M21.1409 4.26696H23.2794L26.4394 13.2385L29.7057 4.26696H31.7911L27.1522 16.0941C26.3294 18.1802 25.0952 19.4821 23.4496 20L22.6942 18.5667C23.9426 18.0051 24.8505 17.0897 25.418 15.8206L21.1409 4.26696Z" fill="white"/>
<path d="M33.536 15.2079V13.4573C34.8482 13.895 36.1746 14.1138 37.5152 14.1138C39.2955 14.1138 40.1857 13.5667 40.1857 12.4726C40.1857 11.4515 39.5651 10.9409 38.3238 10.9409H36.7278C34.3162 10.9409 33.1104 9.81036 33.1104 7.54923C33.1104 5.36105 34.6602 4.26696 37.7599 4.26696C39.0934 4.26696 40.4162 4.44931 41.7285 4.814V6.56455C40.4162 6.12691 39.0934 5.9081 37.7599 5.9081C35.9015 5.9081 34.9723 6.45514 34.9723 7.54923C34.9723 8.57039 35.5575 9.08096 36.7278 9.08096H38.3238C40.8773 9.08096 42.154 10.2115 42.154 12.4726C42.154 14.6608 40.6077 15.7549 37.5152 15.7549C36.1746 15.7549 34.8482 15.5726 33.536 15.2079Z" fill="white"/>
<path d="M44.8139 2.407H46.0481L46.4737 4.26696H49.4953V5.9081H46.7823V12.5055C46.7823 13.5777 47.2327 14.1138 48.1335 14.1138H49.4953V15.7549H47.6228C45.7502 15.7549 44.8139 14.7228 44.8139 12.6586V2.407Z" fill="white"/>
<path d="M51.4743 12.2867C51.4743 9.93071 52.9851 8.75274 56.0068 8.75274C56.9785 8.75274 57.9503 8.82568 58.922 8.97155V7.8884C58.922 6.5682 57.9184 5.9081 55.911 5.9081C54.7619 5.9081 53.5845 6.09045 52.3787 6.45514V4.814C53.5845 4.44931 54.7619 4.26696 55.911 4.26696C59.2306 4.26696 60.8903 5.45587 60.8903 7.8337V15.7549H59.7838L59.1029 14.6171C57.9893 15.3756 56.7764 15.7549 55.4642 15.7549C52.8043 15.7549 51.4743 14.5988 51.4743 12.2867ZM56.0068 10.3939C54.2973 10.3939 53.4426 11.0139 53.4426 12.2538C53.4426 13.4938 54.1165 14.1138 55.4642 14.1138C56.826 14.1138 57.9786 13.7418 58.922 12.9978V10.6127C57.9503 10.4668 56.9785 10.3939 56.0068 10.3939Z" fill="white"/>
<path d="M66.1676 0.109409V15.7549H64.1992V0.109409H66.1676Z" fill="white"/>
<path d="M71.5512 0.109409V15.7549H69.5829V0.109409H71.5512Z" fill="white"/>
<path d="M76.2965 9.96718C76.2965 12.7316 77.4136 14.1138 79.6479 14.1138C81.8822 14.1138 82.9994 12.7316 82.9994 9.96718C82.9994 7.26112 81.8822 5.9081 79.6479 5.9081C77.4136 5.9081 76.2965 7.26112 76.2965 9.96718ZM74.3281 10.0109C74.3281 6.18162 76.1014 4.26696 79.6479 4.26696C83.1945 4.26696 84.9677 6.18162 84.9677 10.0109C84.9677 13.833 83.1945 15.744 79.6479 15.744C76.1156 15.744 74.3423 13.833 74.3281 10.0109Z" fill="white"/>
<path d="M95.0966 6.21444C94.4228 6.03939 93.5893 5.95186 92.5963 5.95186C90.2201 5.95186 89.032 7.27571 89.032 9.92341C89.032 12.6441 90.1563 14.0044 92.4048 14.0044C93.4262 14.0044 94.3235 13.8074 95.0966 13.4136V6.21444ZM97.0649 15.7549C97.0649 18.5266 95.352 19.9125 91.926 19.9125C90.55 19.9125 89.3299 19.7301 88.266 19.3654V17.7243C89.3512 18.089 90.5783 18.2713 91.9473 18.2713C94.0468 18.2713 95.0966 17.4325 95.0966 15.7549V15.2079C94.2596 15.5726 93.3588 15.7549 92.3941 15.7549C88.8618 15.7549 87.0956 13.8184 87.0956 9.9453C87.0956 6.15974 88.9576 4.26696 92.6814 4.26696C94.1922 4.26696 95.6534 4.42378 97.0649 4.73742V15.7549Z" fill="white"/>
<path d="M100.267 15.7549V4.26696H101.597L101.948 5.73304C102.927 4.75565 104.105 4.26696 105.481 4.26696V5.95186C104.147 5.95186 103.066 6.43691 102.236 7.407V15.7549H100.267Z" fill="white"/>
<path d="M106.928 12.2867C106.928 9.93071 108.439 8.75274 111.46 8.75274C112.432 8.75274 113.404 8.82568 114.376 8.97155V7.8884C114.376 6.5682 113.372 5.9081 111.365 5.9081C110.215 5.9081 109.038 6.09045 107.832 6.45514V4.814C109.038 4.44931 110.215 4.26696 111.365 4.26696C114.684 4.26696 116.344 5.45587 116.344 7.8337V15.7549H115.237L114.556 14.6171C113.443 15.3756 112.23 15.7549 110.918 15.7549C108.258 15.7549 106.928 14.5988 106.928 12.2867ZM111.46 10.3939C109.751 10.3939 108.896 11.0139 108.896 12.2538C108.896 13.4938 109.57 14.1138 110.918 14.1138C112.28 14.1138 113.432 13.7418 114.376 12.9978V10.6127C113.404 10.4668 112.432 10.3939 111.46 10.3939Z" fill="white"/>
<path d="M121.515 13.4136C122.288 13.8074 123.15 14.0044 124.1 14.0044C126.42 14.0044 127.579 12.5711 127.579 9.7046C127.579 7.20277 126.427 5.95186 124.121 5.95186C123.128 5.95186 122.259 6.03939 121.515 6.21444V13.4136ZM119.546 4.73742C121.029 4.42378 122.561 4.26696 124.143 4.26696C127.725 4.26696 129.516 6.0868 129.516 9.72648C129.516 13.7454 127.714 15.7549 124.111 15.7549C123.217 15.7549 122.352 15.5726 121.515 15.2079V19.9125H119.546V4.73742Z" fill="white"/>
<path d="M132.186 15.7549V0.109409H134.155V5.51422C135.105 4.68271 136.265 4.26696 137.634 4.26696C140.421 4.26696 141.815 5.69292 141.815 8.54486V15.7549H139.847V8.51204C139.847 6.80525 139.02 5.95186 137.368 5.95186C136.24 5.95186 135.169 6.43691 134.155 7.407V15.7549H132.186Z" fill="white"/>
<path d="M143.688 4.26696H145.826L148.986 13.2385L152.252 4.26696H154.338L149.699 16.0941C148.876 18.1802 147.642 19.4821 145.996 20L145.241 18.5667C146.489 18.0051 147.397 17.0897 147.965 15.8206L143.688 4.26696Z" fill="white"/>
<path d="M157.625 13.7309V15.7549H155.657V13.7309H157.625Z" fill="white"/>
<path d="M162.477 4.26696V15.7549H160.509V4.26696H162.477ZM162.477 0.109409V2.02407H160.509V0.109409H162.477Z" fill="white"/>
<path d="M167.329 9.96718C167.329 12.7316 168.446 14.1138 170.68 14.1138C172.915 14.1138 174.032 12.7316 174.032 9.96718C174.032 7.26112 172.915 5.9081 170.68 5.9081C168.446 5.9081 167.329 7.26112 167.329 9.96718ZM165.36 10.0109C165.36 6.18162 167.134 4.26696 170.68 4.26696C174.227 4.26696 176 6.18162 176 10.0109C176 13.833 174.227 15.744 170.68 15.744C167.148 15.744 165.375 13.833 165.36 10.0109Z" fill="white"/>
</svg>
);
